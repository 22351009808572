<template>
<div class="user-left a-fl">
  <div class="user-info">
    <div class="user-title clearfix">
      <strong>个人资料</strong>
      <!-- <router-link to="/edit" class="icon-edit a-fr">修改</router-link> -->
    </div>
    <div class="user-img">
      <img v-if="userinfo.avatar" :src="userinfo.avatar" onerror="this.src = '../assets/img/img_default_photo.png'"/>
      <img id="photoImg" v-else src="../assets/img/img_default_photo.png" />
    </div>
    <h2 class="user-name">{{userinfo.nickname}}</h2>
  </div>
  <div class="user-nav" ref="leftNav">
    <ul>
      <li class="icon-user"
        :class="{ on: name === 'userinfo'}">
        <router-link to="/edit">个人资料</router-link>
      </li>
      <li class="icon-test"
        :class="{ on: name === 'myform'}">
        <router-link to="form">我的量表</router-link>
      </li>
      <li class="icon-case"
        :class="{ on: name === 'mytest'}">
        <router-link to="InventoryList">我的测评</router-link>
      </li>
      <li class="icon-test"
        :class="{ on: name === 'apply'}">
        <router-link to="applyList">申请列表</router-link>
      </li>
      <li class="icon-feedback"
        :class="{ on: name === 'feedback'}">
        <router-link to="feedback">意见反馈</router-link>
      </li>
      <li class="icon-setting"
        :class="{ on: name === 'setting'}">
        <router-link to="setting">设置</router-link>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
export default {
  name: 'user-left',
  props: {
    'name': {
      type: String
    }
  },
  data () {
    return {
      current: 'user',
      userinfo: {}
    }
  },
  methods: {
    getUserInfo: function () {
      this.$post('user/getMember').then((res) => {
        if (res.code === 1) {
          this.userinfo = res.data
        }
      })
    }
  },
  mounted: function () {
    let h = document.documentElement.clientHeight || document.body.clientHeight
    this.$refs.leftNav.style.minHeight = h - 474 + 'px'
  },
  created () {
    // 获取用户信息
    this.getUserInfo()
  }
}
</script>

<style lang="less" scoped>
.user-left{
  width: 300px;
}
.user-info{
  padding: 0 20px 20px 20px;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
}
.user-title{
  color: #444;
  font-size: 16px;
  height: 60px;
  line-height: 60px;
  padding: 0 5px;
  text-align: left;
}
.user-title a{
  display: inline-block;
  color: #f39902;
}
.icon-edit{
  padding-left: 24px;
  background: url('../assets/img/icon_edit.png') no-repeat left center;
}
.user-img{
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 50%;
  margin: 20px auto;
}
.user-img img{
  width: 100%;
  height: 100%;
}
.user-name{
  height: 40px;
  color: #3b3b3b;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
}
/*user-nav*/
.user-nav{
  background-color: #fff;
  border-radius: 6px;
  padding: 10px 0 20px ;
  margin-top: 20px;
}
.user-nav li{
  padding: 0 20px 0 60px;
  background-repeat: no-repeat;
  background-position: left 20px center;
}
.user-nav li.on{
  border-right: solid #f39902 3px;
  background-color: #fff7e6;
}
.user-nav li a{
  display: block;
  color: #5a5a5a;
  font-size: 16px;
  line-height: 60px;
}
.user-nav li a:hover{
  color: #f39902;
}
.icon-user{
  background-image: url('../assets/img/icon_user.png');
}
.icon-test{
  background-image: url('../assets/img/icon_test.png');
}
.icon-case{
  background-image: url('../assets/img/icon_case.png');
}
.icon-feedback{
  background-image: url('../assets/img/icon_feedback.png');
}
.icon-setting{
  background-image: url('../assets/img/icon_setting.png');
}
</style>
