<template>
  <div class="edit">
    <!--顶部-->
    <header-nav :current="current"></header-nav>
    <div class="content" ref="content">
      <div class="box clearfix">
        <user-left :name="'myform'"></user-left>
        <div class="user-right" ref="right">
          <div class="title-top"><span>已购测评</span></div>
          <div class="a-over-h search-sort">
            <div class="search a-fl">
              <div class="search-form a-fr a-over-h">
                <input class="a-fl" type="text" v-model="keywords" placeholder="请输入搜索关键词"/>
                <button class="a-fr icon-search" @click="searchFun"></button>
              </div>
            </div>
            <div class="sort-btn a-fr">
              <button title="升序排列" class="icon-ase" :class="{ active: sort === 'ase' }"
                      @click="changeSort('ase')"></button>
              <button title="降序排列" class="icon-dec" :class="{ active: sort === 'desc' }"
                      @click="changeSort('desc')"></button>
            </div>
          </div>
          <div class="table-box">
            <table>
              <tr class="a-ta-c">
                <td class="tx-l">测评量表名称</td>
                <td>购买次数</td>
                <td>已用次数</td>
                <td>剩余次数</td>
                <td>操作</td>
              </tr>
              <tr v-for="(item, index) in td_case" :key="index">
                <td>
                  <div class="table-name a-e1">{{item.title}}</div>
                </td>
                <td class="a-ta-c">{{item.buy_count}}</td>
                <td class="a-ta-c">{{(item.buy_count)-(item.count)}}</td>
                <td class="a-ta-c">
                  <span class="table-number">{{item.count}}</span>
                </td>
                <td>
                  <div class="btn1-box">
<!--                    <span @click="$router.push('/testcenter?type_id=' + item.title_id + '&id=' + item.title_id)">开始测评</span>-->
                    <span @click="$router.push('/baseinfo?id=' + item.title_id)">开始测评</span>
                    <span @click="$router.push('/testcenter?type_id=' + item.title_id + '&id=' + item.title_id)">购买量表</span>
                  </div>
                </td>
              </tr>
            </table>
            <more-list :page="page" :more="more" :nodata="nodata" @moreCallback="moreCallback"></more-list>
          </div>
        </div>
      </div>
    </div>
    <!--底部-->
    <footer-nav></footer-nav>
  </div>
</template>

<script>
  import HeaderNav from '@/components/HeaderNav'
  import FooterNav from '@/components/FooterNav'
  import MoreList from '@/components/MoreList'
  import UserLeft from '@/components/UserLeft'

  export default {
    name: 'user',
    data() {
      return {
        current: 'user',
        td_case: [],
        sort: 'ase',
        keywords: '',
        page: 1,
        more: false,
        nodata: false
      }
    },
    methods: {
      searchFun: function () {
        this.getList()
      },
      changeSort: function (sort) {
        this.sort = sort
        this.page = 1
        this.getList()
      },
      getList: function () {
        let params = {
          title: this.keywords,
          order: this.sort,
          page: 1,
          limit: 10
        }
        this.$post('question/myQuestion', params).then(res => {
          if (res.code === 1) {
            if (!res.data.totalCount) {
              this.nodata = true
              this.td_case = []
              return false
            } else {
              this.nodata = false
            }
            if (res.data.listData.length) {
              this.nodata = false
              this.td_case = res.data.listData
            } else {
              this.td_case = []
              this.nodata = true
            }
            if (res.data.listData.length < res.data.perPage) {
              this.more = false
            } else {
              this.more = true
            }
          } else {
            this.$layer.msg(res.msg)
            this.nodata = true
          }
        }).catch(response => {
          this.$layer.msg('网络异常，请稍后重试！')
          this.nodata = true
        })
      },
      moreCallback: function () {
        let params = {
          title: this.keywords,
          order: this.sort,
          page: ++this.page,
          count: 10
        }
        this.$post('evaluation_list', params).then(res => {
          if (res.code === 1) {
            for (let i in res.data.listData) {
              this.td_case.push(res.data.listData[i])
            }
            if (res.data.listData.length < res.data.perPage) {
              this.more = false
            } else {
              this.more = true
            }
          } else {
            this.$layer.msg(res.msg)
            this.more = false
          }
        }).catch(response => {
          this.$layer.msg('网络异常，请稍后重试！')
        })
      }
    },
    watch: {
      keywords() {
        this.page = 1
        this.getList()
      }
    },
    components: {
      HeaderNav,
      FooterNav,
      MoreList,
      UserLeft
    },
    mounted: function () {
      var h = document.documentElement.clientHeight || document.body.clientHeight
      this.$refs.content.style.minHeight = h - 124 + 'px'
      this.$refs.right.style.minHeight = h - 144 + 'px'
    },
    created() {
      this.getList()
    }
  }
</script>

<style lang="less" scoped>
  @import url('../assets/css/page/history');

  .btn1-box {
    min-width: 60px;
    text-align: center;
  }

  .btn1-box span {
    color: #f39902;
    padding: 0 5px;
    cursor: pointer;
  }

  .btn1-box span:hover {
    text-decoration: underline;
  }
</style>
